import React from 'react'
import styles from './styles/type13.module.scss'
import classNames from 'classnames'

interface ListType13 {
  name: string
  links: Array<{ name: string; path: string }>
}

export default function Type13({ name, links }: ListType13) {
  return (
    <div className={styles.type13}>
      <h3 className={classNames([styles.title, 'text-elli-2'])}>
        {name ?? ''}
      </h3>
      <ul className={styles.ul}>
        {(links || []).map(item => (
          <li key={item.name} className={styles.item}>
            <a href={item.path}>{item.name}</a>
          </li>
        ))}
      </ul>
    </div>
  )
}
